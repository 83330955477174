<template>
  <v-container fluid>
    <v-card
      flat
      class="custom-grey-border remove-border-radius"
      :disabled="pageLoading"
    >
      <v-card-title
        class="headline grey lighten-4"
        @click="show_notes_attachment = !show_notes_attachment"
      >
        <h3 class="font-weight-700 custom-headline color-custom-blue">
          <v-icon>
            {{ !show_notes_attachment ? "mdi-chevron-down" : "mdi-chevron-up" }}
          </v-icon>
          Notes & Attachments
        </h3>
      </v-card-title>
      <v-expand-transition>
        <v-card-text v-show="show_notes_attachment" class="p-6 font-size-16">
          <v-row>
            <v-col v-if="!isPurchaseOrder" cols="6" class="pt-0">
              <label for="client-notes" class="font-weight-700 font-size-16"
                >Client Contract Notes</label
              >
              <editor v-model="notesAttachment.client_notes" />
            </v-col>
            <v-col :cols="isPurchaseOrder ? 12 : 6" class="pt-0">
              <label for="admin-notes" class="font-weight-700 font-size-16"
                >Internal Admin Notes</label
              >
              <editor v-model="notesAttachment.admin_notes" />
            </v-col>
          </v-row>
          <div class="mt-4" v-if="getPermission('attachment:create')">
            <h3 class="font-weight-700 custom-headline color-custom-blue">
              Attachments
            </h3>
            <div class="text-center">
              <FileTemplate
                :attachments="attachment"
                v-on:file:updated="updateFiles"
                allowUpload
              ></FileTemplate>
            </div>
          </div>
          <div
            v-if="
              false && !disableNotification && !isPurchaseOrder && !isProposal
            "
            class="mt-4 custom-border-top"
          >
            <label class="pt-4 font-weight-700 font-size-16"
              >Email Notification</label
            >
            <div class="d-flex">
              <v-checkbox
                v-model.trim="notesAttachment.notify_admin"
                label="Admin"
                :value="1"
                hide-details
                color="cyan"
                class="mr-4"
                :disabled="pageLoading"
              ></v-checkbox>
              <v-checkbox
                v-model.trim="notesAttachment.notify_customer"
                label="Client"
                :value="1"
                hide-details
                color="cyan"
                class="mr-4"
                :disabled="pageLoading"
              ></v-checkbox>
              <v-checkbox
                v-if="false"
                v-model.trim="notesAttachment.notify_engineer"
                label="Engineer"
                :value="1"
                hide-details
                color="cyan"
                :disabled="pageLoading"
              ></v-checkbox>
            </div>
          </div>
        </v-card-text>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "notes-attachment",
  data() {
    return {
      show_notes_attachment: false,

      timeoutLimit: 200,
      timeout: null,
      attachment: new Array(),
      notesAttachment: {
        admin_notes: null,
        client_notes: null,
        documents: new Array(),
        notify_admin: 0,
        notify_customer: 0,
        notify_engineer: 0,
      },
    };
  },
  props: {
    disableNotification: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    isTicket: {
      type: Boolean,
      default: false,
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false,
    },
    isProposal: {
      type: Boolean,
      default: false,
    },
    updateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    documents: {
      deep: true,
      immediate: true,
      handler(param) {
        this.notesAttachment.documents = param;
      },
    },
    notesAttachment: {
      deep: true,
      immediate: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          NoteAttachmentEventBus.$emit(
            "update:notes-attachment",
            _this.notesAttachment
          );
        }, _this.timeoutLimit);
      },
    },
    updateData: {
      deep: true,
      immediate: true,
      handler() {
        this.setUpdateData();
      },
    },
  },
  methods: {
    updateFiles(param) {
      this.notesAttachment.documents = param;
    },
    setUpdateData() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.updateData) === false) {
        _this.notesAttachment = {
          admin_notes: _this.updateData.admin_remark,
          client_notes: _this.updateData.client_remark,
          documents: new Array(),
          notify_admin: +_this.updateData.notify_admin,
          notify_customer: +_this.updateData.notify_customer,
          notify_engineer: +_this.updateData.notify_engineer,
        };
        _this.attachment = _this.updateData.documents
          ? _this.updateData.documents
          : new Array();
      }
    },
  },
  components: {
    FileTemplate,
    editor: TinyMCE,
  },
  mounted() {
    NoteAttachmentEventBus.$on("update-value:internal-notes", (value) => {
      this.notesAttachment.admin_notes = value;
      NoteAttachmentEventBus.$off("update-value:internal-notes");
    });
    NoteAttachmentEventBus.$on("update-value:attachments", (value) => {
      this.attachment = value;
      this.notesAttachment.documents = value;
      NoteAttachmentEventBus.$off("update-value:attachments");
    });
  },
};
</script>
