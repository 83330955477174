<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'job-create'"
    v-if="getPermission('job:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0">
            Incident For
            <!-- <pre>{{customer}}</pre> --></v-flex
          >
          <v-flex
            class="m-0 form-title-create-link pl-2"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
            v-on:click="refCustomerDialog = true"
          >
            <template v-if="lodash.isEmpty(customer)">Client Name</template>
            <template v-else>
              <div class="cursor-pointer">
                <span v-on:click="checkCustomer(customer.id)">
                  {{ customer.display_name }}
                </span>
              </div>
            </template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack()"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="createReport()"
      >
        <template v-if="doUpdate">Update </template>
        <template v-else>Save </template>
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="jobForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="createReport()"
      >
        <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: calc(100vh - 185px); position: relative"
          > -->
        <div class="p-5 pt-0">
          <v-row>
            <v-col cols="12" v-if="getPermission('job:create')">
              <v-container fluid>
                <v-card flat class="remove-border-radius">
                  <v-card-text ref="overview" class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="7" class="pb-0">
                        <div>
                          <label
                            class="font-weight-600 font-size-18 required ml-1"
                            >Title</label
                          >
                          <v-text-field
                            v-model.trim="reportData.title"
                            dense
                            filled
                            label="Title"
                            solo
                            flat
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            color="cyan"
                            :rules="[
                              validateRules.required(reportData.title, 'Title'),
                              validateRules.minLength(
                                reportData.title,
                                'Title',
                                1
                              ),
                              validateRules.maxLength(
                                reportData.title,
                                'Title',
                                100
                              ),
                            ]"
                          ></v-text-field>
                          <label class="font-weight-600 font-size-18 ml-1"
                            >Remark</label
                          >
                          <v-textarea
                            v-model.trim="reportData.remark"
                            auto-grow
                            dense
                            filled
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            color="cyan"
                            label="Remark"
                            solo
                            v-on:keypress="(e) => manageLimit(e)"
                            v-on:paste="(e) => onPaste(e)"
                            flat
                            row-height="25"
                          ></v-textarea>
                          <div class="text-right">
                            {{
                              reportData.remark ? reportData.remark.length : 0
                            }}/200
                          </div>
                        </div>

                        <v-layout class="my-4">
                          <v-flex md6 class="mr-2 custom-border-right">
                            <table width="100%">
                              <tr>
                                <td class="font-weight-600">
                                  <label>Service Location</label>
                                  <v-icon
                                    v-if="!jobId"
                                    class="ml-3"
                                    v-on:click="customerPropertyDialog = true"
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                  <v-icon
                                    class="ml-3"
                                    v-on:click="routeToServiceHistory()"
                                    color="cyan"
                                    small
                                    >mdi-history</v-icon
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td class="py-0">
                                  <label>{{ property.property_address }}</label>
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-600">
                                  <label>Contact details</label>
                                  <v-chip
                                    v-if="property_contact_person.tenant"
                                    small
                                    color="green white--text"
                                    label
                                    class="ml-2 tenant-small-chip"
                                    >Tenant</v-chip
                                  >
                                  <v-icon
                                    v-if="!jobId"
                                    class="ml-3"
                                    v-on:click="
                                      person_type = 'property';
                                      customerPersonDialog = true;
                                    "
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr v-if="property_contact_person.display_name">
                                <td class="py-0">
                                  <label>{{
                                    property_contact_person.display_name
                                  }}</label>
                                </td>
                              </tr>
                              <em v-else class="text-muted">
                                no display name</em
                              >
                              <tr>
                                <td class="py-0">
                                  {{ property_contact_person.primary_phone }}
                                  <label
                                    v-if="property_contact_person.primary_phone"
                                    >{{
                                      property_contact_person.primary_phone
                                    }}</label
                                  >
                                  <em v-else class="text-muted">
                                    no phone number</em
                                  >
                                </td>
                              </tr>

                              <tr>
                                <td class="py-0">
                                  <label
                                    v-if="property_contact_person.primary_email"
                                    >{{
                                      property_contact_person.primary_email
                                    }}</label
                                  >
                                  <em v-else class="text-muted">
                                    no email address</em
                                  >
                                </td>
                              </tr>

                              <!--   <tr>
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                jobCreate.property_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              jobCreate.property_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr> -->
                            </table>
                          </v-flex>
                          <v-flex md6 class="ml-2">
                            <table width="100%">
                              <tr>
                                <td class="font-weight-600">
                                  <label>Billing Address</label>
                                  <v-icon
                                    v-if="!jobId"
                                    class="ml-3"
                                    v-on:click="customerBillingDialog = true"
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td class="py-0">
                                  <label>{{ billing.property_address }}</label>
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-600">
                                  <label>Contact details</label>
                                  <v-chip
                                    v-if="billing_contact_person.tenant"
                                    small
                                    color="green white--text"
                                    label
                                    class="ml-2 tenant-small-chip"
                                    >Tenant</v-chip
                                  >
                                  <v-icon
                                    v-if="!jobId"
                                    class="ml-3"
                                    v-on:click="
                                      person_type = 'billing';
                                      customerPersonDialog = true;
                                    "
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr v-if="billing_contact_person.display_name">
                                <td class="py-0">
                                  <label>{{
                                    billing_contact_person.display_name
                                  }}</label>
                                </td>
                              </tr>
                              <tr>
                                <td class="py-0">
                                  <label
                                    v-if="billing_contact_person.primary_phone"
                                    >{{
                                      billing_contact_person.primary_phone
                                    }}</label
                                  >
                                  <em v-else class="text-muted">
                                    no phone number</em
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td class="py-0">
                                  <label
                                    v-if="billing_contact_person.primary_email"
                                    >{{
                                      billing_contact_person.primary_email
                                    }}</label
                                  >
                                  <em v-else class="text-muted">
                                    no email address</em
                                  >
                                </td>
                              </tr>
                              <!--  <tr>
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                jobCreate.billing_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              jobCreate.billing_person_notify == 1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr> -->
                            </table>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <v-col cols="5" class="pb-0">
                        <table width="100%">
                          <!--  <tr v-if="jobCreate.barcode">
                              <td width="150" class="font-weight-600">
                                <label>Job number</label>
                              </td>
                              <td class="font-weight-700">
                                <label>{{ jobCreate.barcode }}</label>
                              </td>
                            </tr>
                            <tr v-if="jobCreate.visit_barcode">
                              <td width="150" class="font-weight-600">
                                <label>Visit number</label>
                              </td>
                              <td class="font-weight-700">
                                <label>{{ jobCreate.visit_barcode }}</label>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="reference-no">Reference #</label>
                              </td>
                              <td>
                                <v-text-field
                                  id="reference-no"
                                  v-model.trim="jobCreate.reference"
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="Reference #"
                                  :rules="[
                                    validateRules.minLength(
                                      jobCreate.reference,
                                      'Reference',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      jobCreate.reference,
                                      'Reference',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                  hide-details
                                />
                              </td>
                            </tr> -->
                          <!-- <tr>
                              <td width="150" class="font-weight-600">
                                <label for="po-number">PO Number</label>
                              </td>
                              <td>
                                <v-text-field
                                  id="po-number"
                                  v-model.trim="jobCreate.po_number"
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="PO Number"
                                  :rules="[
                                    validateRules.minLength(
                                      jobCreate.po_number,
                                      'PO Number',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      jobCreate.po_number,
                                      'PO Number',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                ></v-text-field>
                              </td>
                            </tr> -->
                          <!-- <tr>
                              <td width="150" class="font-weight-600">
                                <label for="payment-mode">Payment Mode</label>
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="payment-mode"
                                  :items="paymentModeList"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model.trim="jobCreate.payment_mode"
                                  label="Payment Mode"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="value"
                                  item-value="id"
                                  hide-details
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="'No Payment Mode Found.'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr> -->
                          <!-- <tr>
                                <td width="150" class="font-weight-600">
                                  <label for="quotation">Quotation</label>
                                </td>
                                <td>
                                  <v-autocomplete
                                    dense
                                    color="cyan"
                                    filled
                                    id="quotation"
                                    :items="quotationList"
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    v-model.trim="quotationId"
                                    label="Quotation"
                                    solo
                                    flat
                                    item-color="cyan"
                                    item-text="value"
                                    item-value="id"
                                    hide-details
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            v-html="'No Quotation Found.'"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-autocomplete>
                                </td>
                              </tr> -->
                          <!-- <tr>
                              <td width="150" class="font-weight-600">
                                <label for="job-type" class="required"
                                  >Job Type</label
                                >
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="job-type"
                                  :items="jobTypeList"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model.trim="jobCreate.job_type"
                                  label="Job Type"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="value"
                                  item-value="id"
                                  hide-details
                                  multiple
                                  :rules="[
                                    validateRules.required(
                                      jobCreate.job_type,
                                      'Job Type'
                                    ),
                                  ]"
                                  return-object
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="'No Job Type Found.'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="skill">Skills</label>
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="skill"
                                  :items="skillList"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model.trim="jobCreate.skill"
                                  label="Skills"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="value"
                                  item-value="id"
                                  hide-details
                                  multiple
                                  return-object
                                  v-on:change="updateSkills()"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="'No Skill Found.'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr> -->
                          <tr>
                            <td width="150" class="font-weight-600">
                              <label for="priority">Assign</label>
                            </td>
                            <td>
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="Assign"
                                :items="userList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="reportData.user"
                                label="Assign"
                                solo
                                flat
                                item-color="cyan"
                                item-text="display_name"
                                item-value="id"
                                hide-details
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Assign Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td width="150" class="font-weight-600">
                              <label for="priority">Priority</label>
                            </td>
                            <td>
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="priority"
                                :items="priorityList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="reportData.priority"
                                label="Priority"
                                solo
                                flat
                                item-color="cyan"
                                item-text="text"
                                item-value="value"
                                hide-details
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Priority Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td width="150" class="font-weight-600">
                              <label for="priority">Impact</label>
                            </td>
                            <td>
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="Impact"
                                :items="impactList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="reportData.impact"
                                label="Impact"
                                solo
                                flat
                                item-color="cyan"
                                item-text="text"
                                item-value="text"
                                hide-details
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Impact Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="150"
                              class="font-weight-600"
                              valign="top"
                            >
                              <label for="tag">Tags </label>
                            </td>
                            <td>
                              <v-combobox
                                v-model="reportData.tags"
                                :items="tagList"
                                solo
                                flat
                                multiple
                                chips
                                dense
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                placeholder="Tags"
                                label="Tags"
                                hide-details
                                color="cyan"
                                item-color="cyan"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="150"
                              class="font-weight-600"
                              valign="top"
                            >
                              <label for="tag">Due Date </label>
                            </td>
                            <td>
                              <div class="d-flex">
                                <div>
                                  <DatePicker
                                    solo
                                    flat
                                    placeholder="Due Date"
                                    v-model="reportData.due_date"
                                  ></DatePicker>
                                </div>
                                <div>
                                  <v-autocomplete
                                    dense
                                    color="cyan"
                                    filled
                                    id="end-time"
                                    :items="timeList"
                                    v-model.trim="reportData.due_time"
                                    placeholder="Due Time"
                                    label="Due Time"
                                    solo
                                    flat
                                    item-color="cyan"
                                    hide-details
                                  />
                                </div>
                              </div>
                            </td>
                            <!--  <td colspan="3">
                              
                            </td> -->
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12" class="job-schedule" v-if="false">
              <v-container fluid>
                <v-tabs
                  v-model="jobCreate.schedule_tab"
                  background-color="transparent"
                  centered
                  :disabled="pageLoading"
                  color="cyan"
                  icons-and-text
                  class="mb-3"
                >
                  <v-tab href="#one-off">
                    <div class="custom-tab-title d-flex">
                      <div class="custom-tab-icon pr-2">
                        <v-icon left large color="gray">mdi-calendar</v-icon>
                      </div>
                      <div class="text-left">
                        <h3 class="text-capitalize">One-Off Job</h3>
                        <p class="m-0 text-capitalize">
                          A one time job with one or more visits
                        </p>
                      </div>
                    </div>
                  </v-tab>
                  <v-tab href="#recurring">
                    <div class="custom-tab-title d-flex">
                      <div class="custom-tab-icon pr-2">
                        <v-icon left large color="gray"
                          >mdi-calendar-multiple</v-icon
                        >
                      </div>
                      <div class="text-left">
                        <h3 class="text-capitalize">Recurring Job</h3>
                        <p class="m-0 text-capitalize">
                          A contract job with repeating visits
                        </p>
                      </div>
                    </div>
                  </v-tab>
                </v-tabs>
                <v-tabs-items
                  v-model="jobCreate.schedule_tab"
                  class="remvoe-overflow"
                >
                  <v-tab-item value="one-off">
                    <v-layout>
                      <v-flex :md4="show_calendar" :md12="!show_calendar">
                        <OneOffSchedule
                          :show-calendar="show_calendar"
                          :pageLoading="pageLoading"
                        ></OneOffSchedule>
                      </v-flex>
                      <v-flex v-if="show_calendar" md8>
                        <Calendar
                          :show-calendar="show_calendar"
                          :pageLoading="pageLoading"
                        ></Calendar>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item value="recurring">
                    <v-layout>
                      <v-flex :md4="show_calendar" :md12="!show_calendar">
                        <RecurringSchedule
                          :show-calendar="show_calendar"
                          :pageLoading="pageLoading"
                        ></RecurringSchedule>
                      </v-flex>
                      <v-flex v-if="show_calendar" md8>
                        <Calendar
                          :show-calendar="show_calendar"
                          :pageLoading="pageLoading"
                        ></Calendar>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                </v-tabs-items>
              </v-container>
            </v-col>
            <v-col cols="12" v-if="getPermission('line-item:create') && false">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text ref="lineItem" class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="pt-0 pb-0">
                        <JobLineItemNew
                          is-job
                          can-update
                          :property-id="jobProperty"
                          :db-line-items="dbLineItems"
                          :db-equipments="dbEquipments"
                          :discount-value="jobCreate.discount_value"
                          :discount-value-type="jobCreate.discount_value_type"
                          :apply-tax="jobCreate.tax_applied"
                          :adjustment-value="jobCreate.adjustment"
                          v-on:update:equipment="updateEquipment($event)"
                          v-on:update:line-item="updateLineItem($event)"
                          v-on:update:line-item-calculation="
                            updateLineItemCalculation($event)
                          "
                        ></JobLineItemNew>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12">
              <label class="custom-form-label width-100"
                >Images
                <!--  <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 images are not allowed</span>
                    </v-tooltip> -->
              </label>
              <FileTemplate
                allowUpload
                isMinDisplay
                acceptFile="image/png, image/jpeg, image/jpg"
                :attachments="reportData.report_images"
                v-on:file:updated="updateImages"
              ></FileTemplate>
              <JobTermsConditions
                v-if="false"
                :update-data="updateData"
                :page-loading="pageLoading"
              ></JobTermsConditions>
            </v-col>
            <v-col cols="12">
              <JobNotesAttachment
                v-if="false"
                :update-data="updateData"
                :page-loading="pageLoading"
              ></JobNotesAttachment>
            </v-col>
          </v-row>
        </div>
        <!-- </perfect-scrollbar> -->
      </v-form>

      <template v-if="refEntityId && refCustomerDialog">
        <RefCustomerDialog
          :entity="refEntityId"
          :customer-dialog="refCustomerDialog"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomer="refSelectCustomer"
        ></RefCustomerDialog>
      </template>
      <template v-if="refCustomerPropertyDialog">
        <RefCustomerPropertyDialog
          disabled-auto-select
          :customer-property-dialog="refCustomerPropertyDialog"
          :customer="refCustomerId"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomerProperty="refSelectCustomerProperty"
        ></RefCustomerPropertyDialog>
      </template>

      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          disabled-auto-select
          :customerPersonDialog="customerPersonDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          disabled-auto-select
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
      <template v-if="customerBillingDialog">
        <CustomerBillingDialog
          disabled-auto-select
          :customerBillingDialog="customerBillingDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerBillingDialog>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { QUERY, POST, PUT, GET } from "@/core/services/store/request.module";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CustomerBillingDialog from "@/view/pages/partials/Select-Customer-Billing.vue";
import JobLineItemNew from "@/view/pages/partials/Line-Item-New.vue";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import JobTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import JobNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import OneOffSchedule from "@/view/pages/job/partials/One-Off-Schedule.vue";
import { JobEventBus } from "@/core/lib/job/job.lib";
import Calendar from "@/view/pages/partials/Calendar.vue";
import RecurringSchedule from "@/view/pages/job/partials/Recurring-Schedule.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
/*  import Barcode from "@/view/pages/partials/Barcode.vue"; */
import RefCustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import ObjectPath from "object-path";
import RefCustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import { toSafeInteger, isEmpty, filter, isArray } from "lodash";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "job-create",
  title: "Create Job",
  data() {
    return {
      updateId: 0,
      doUpdate: false,
      refEntityId: 1,
      refCustomerDialog: false,
      refCustomerPropertyDialog: false,
      formLoading: false,
      pageLoading: false,
      preContract: 0,
      jobCreated: false,
      /*   lineItemCreated: false, */
      scheduleCreated: false,
      engineerAssigned: false,
      show_calendar: false,
      customer: {},
      property: {},
      billing: {},
      barcodeSetting: {},
      tagList: [],
      visitBarcodes: [],
      impactList: [],
      userList: [],
      jobCreate: {
        job_title: null,
        description: null,
        po_number: null,
        payment_mode: 0,
        property_person_notify: 1,
        billing_person_notify: 1,
        barcode: null,
        reference: null,
        job_type: null,
        skill: null,
        tags: [],
        priority: 3,
        schedule_tab: "one-off",
        discount_value: null,
        discount_value_type: 1,
        tax_applied: null,
        adjustment: null,
        schedule_later: null,
        total_occurence: 1,
      },
      priorityList: [
        {
          value: "high",
          text: "High",
        },
        {
          value: "medium",
          text: "Medium",
        },
        {
          value: "low",
          text: "Low",
        },
      ],
      jobScheduleTypeList: [
        {
          id: "one-off",
          value: "One-Off Job",
        },
        {
          id: "recurring",
          value: "Recurring Job",
        },
      ],
      customerPersonDialog: false,
      customerBillingDialog: false,
      customerPropertyDialog: false,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      person_type: null,
      quotationId: null,
      invoiceId: null,
      jobId: null,
      incidentId: 0,
      property_contact_person: {},
      billing_contact_person: {},
      jobTypeList: [],
      skillList: [],
      customerContractList: [],
      paymentModeList: [],
      quotationList: [],
      lineItem: [],
      equipments: [],
      incidentCustomer: 0,
      incidentBilling: 0,
      incidentProperty: 0,
      incidentPropertyPerson: 0,
      dbLineItems: [],
      dbEquipments: [],
      note_attachments: {},
      terms_condition: null,
      lineItemCalculation: {
        taxApplied: 0,
        discountValue: 0,
        adjustmentAmount: 0,
        discountType: 1,
        discountValueType: 0,
      },
      updateData: {
        term_conditions: null,
        admin_remark: null,
        client_remark: null,
        notify_admin: 0,
        notify_customer: 0,
        notify_engineer: 0,
        documents: [],
      },
      reportData: {
        id: 0,
        customer: 0,
        property: 0,
        equipment: 0,
        user: 0,
        tags: [],
        title: null,
        priority: null,
        impact: null,
        due_date: null,
        status: 1,
        due_time: null,
        remark: null,
        images: [],
        report_images: [],
      },
      recurring_pattern: {},
      recurring_dates: [],
      recurring_schedule: {},
      one_off_schedule: {},
      assigned_engineers: {},
      response_schedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      },
    };
  },
  components: {
    Calendar,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    CustomerBillingDialog,
    JobLineItemNew,
    CreateUpdateTemplate,
    JobTermsConditions,
    OneOffSchedule,
    RecurringSchedule,
    JobNotesAttachment,
    FileTemplate,
    /*  Barcode, */
    DatePicker,
    RefCustomerPropertyDialog,
    RefCustomerDialog,
  },
  methods: {
    updateImages(param) {
      this.reportData.images = param;
    },
    refCloseDialog() {
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
    },
    refResetAll() {
      this.refEntityId = 1;
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
      this.refCustomerId = 0;
    },

    refSelectCustomer(param) {
      this.refCustomerId = param;
      this.$nextTick(() => {
        this.forcePush = true;
        this.refCustomerDialog = false;
        this.refCustomerPropertyDialog = true;
      });
    },

    checkCustomer(data) {
      this.CustomerId = data;

      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: {
            id: this.CustomerId ?? undefined,
          },
        })
      );
    },

    updateSkills() {
      const { skill } = this.jobCreate;
      JobScheduleTeamEventBus.$emit(
        "update:skills",
        skill.map((x) => x.value)
      );
    },

    routeToServiceHistory() {
      this.forcePush = true;
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("property.detail", {
            params: { id: this.property.id },
            query: {
              tab: "property-history",
            },
          })
        );
      });
    },

    refSelectCustomerProperty(param) {
      this.refPropertyId = param;
      /*   let route =  */ this.getDefaultRoute("incident.create", {
        query: {
          customer: this.refCustomerId,
          property: this.refPropertyId,
        },
      });

      (this.incidentProperty = this.refPropertyId),
        (this.incidentCustomer = this.refCustomerId),
        this.pushToRouteQuotation();
      this.closeDialog();
      this.refCustomerPropertyDialog = false;
    },
    closeDialog() {
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
      this.customerPropertyDialog = false;
    },
    pushToRouteQuotation() {
      let routeName = "";
      if (this.incidentId && this.incidentId > 0) {
        routeName = "incident.update";
      } else {
        routeName = "incident.create";
      }
      this.$router
        .replace(
          this.getDefaultRoute(routeName, {
            query: {
              customer: this.refCustomerId,
              billing: this.incidentBilling,
              property: this.incidentProperty,
              property_person: this.incidentPropertyPerson,
              billing_person: this.incidentBillingPerson,
              incident: this.incidentId,
              /* 
              billing: this.incidentBilling,
              property: this.refPropertyId,
              incidenrt : this.incidentId,
              property_person: this.incidentPropertyPerson,
              billing_person: this.incidentBillingPerson, */
            },
          })
        )
        .then(() => {
          this.getOptions();
        });
    },

    selectCustomerPerson(param) {
      if (this.person_type == "property") {
        if (this.incidentPropertyPerson == param) {
          this.closeDialog();
          return false;
        }
        this.incidentPropertyPerson = param;
      }
      if (this.person_type == "billing") {
        if (this.incidentBillingPerson == param) {
          this.closeDialog();
          return false;
        }
        this.incidentBillingPerson = param;
      }
      this.closeDialog();
      this.pushToRouteQuotation();
    },
    selectCustomerProperty(param) {
      if (this.incidentProperty == param) {
        this.closeDialog();
        return false;
      }
      this.forcePush = true;
      this.incidentProperty = param;
      this.closeDialog();
      this.pushToRouteQuotation();
    },

    selectCustomerBilling(param) {
      if (this.incidentBilling == param) {
        this.closeDialog();
        return false;
      }
      this.incidentBilling = param;
      this.closeDialog();
      this.pushToRouteQuotation();
    },
    pushToRouteJob() {
      let routeName = "";
      if (this.incidentId && this.incidentId > 0) {
        routeName = "incident.update";
      } else {
        routeName = "incident.create";
      }
      this.$router
        .replace(
          this.getDefaultRoute(routeName, {
            query: {
              billing: this.incidentBilling,
              property: this.incidentProperty,
              property_person: this.incidentPropertyPerson,
              billing_person: this.incidentBillingPerson,
              incident: this.incidentId,

              /* billing: this.jobBilling,
              property_person: this.jobPropertyPerson,
              billing_person: this.jobBillingPerson,
              incident: this.incidentId,
              customer: this.refCustomerId,
              property: this.refPropertyId, */
            },
          })
        )
        .then(() => {
          /*   this.getOptions(); */
        });
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.reportData.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.reportData.remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (this.reportData.remark && this.reportData.remark.length > 199) {
        e.preventDefault();
      }
    },
    createReport() {
      const _this = this;

      const validateStatus = _this.$refs.jobForm.validate();

      const formErrors = _this.validateForm(_this.$refs.jobForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (_this.incidentCustomer == 0) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select customer then try again.")
        );
        return false;
      }
      if (!validateStatus) {
        return false;
      }
      let requestTYPE = "";
      let url = "";
      this.reportData.customer = this.incidentCustomer;
      this.reportData.property = this.incidentProperty;
      console.log(this.reportData, "this.reportData");
      if (this.incidentId > 0) {
        url = "incident-report/setting/" + this.incidentId;
        requestTYPE = PUT;
      } else {
        url = "incident-report/setting";
        requestTYPE = POST;
      }
      _this.$store
        .dispatch(requestTYPE, {
          url: url,
          data: this.reportData,
        })
        .then((data) => {
          this.$nextTick(() => {
            this.forcePush = true;
            this.$router.push(
              this.getDefaultRoute("incident.detail", {
                params: { id: data.data.id },
              })
            );

            /* this.$router.push(
              _this.getDefaultRoute("incident-report", {
                query: {
                  status: "all",
                },
              })
            ); */
          });
          this.$emit("close", true);
          this.$emit("refresh", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    /*  async updateOrCreate() {
        const _this = this;
  
        const validateStatus = _this.$refs.jobForm.validate();
  
        const formErrors = _this.validateForm(_this.$refs.jobForm);
  
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }
  
        if (_this.incidentCustomer == 0) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select customer then try again.")
        );
        return false;
      }
  
        const line_items = concat(_this.equipments, _this.lineItem);
  
        let validateLineItem = compact(
          map(line_items, function (row) {
            return row.product_id;
          })
        );
  
        
       
  
        if (!validateStatus) {
          return false;
        }
  
      
  
        _this.formLoading = false;
  
        _this.$nextTick(() => {
          this.forcePush = true;
          if (formData.type == 1) {
            _this.$router.push(
              _this.getDefaultRoute("visit.detail", {
                params: { id: _this.incidentId },
              })
            );
          } else {
            _this.$router.push(_this.getDefaultRoute("visit", {}));
          }
        });
      },
      getLineItems(data) {
        const _this = this;
        return new Promise((resolve, reject) => {
          _this.$store
            .dispatch(QUERY, {
              url: "line-item/new",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }, */
    setQuotationData(param) {
      const _this = this;

      //  _this.jobCreate.job_title = param.job_title;
      // _this.jobCreate.description = param.description;
      _this.jobCreate.property_person_notify = param.property_person_notify;
      _this.jobCreate.billing_person_notify = param.billing_person_notify;
      //  _this.jobCreate.reference = `Quotation # ${param.barcode}`;
      _this.jobCreate.discount_value = param.discount_value;
      _this.jobCreate.discount_value_type = param.discount_value_type;
      _this.jobCreate.tax_applied = param.tax_applied;

      _this.jobCreate.adjustment = param.adjustment;

      //  _this.updateData.term_conditions = param.term_conditions;
      // _this.updateData.admin_remark = param.admin_remark;
      //  _this.updateData.client_remark = param.client_remark;
      //  _this.updateData.notify_admin = param.notify_admin;
      //  _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          quotation: _this.quotationId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              project_price: data[i].project_price,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return row.product_type == "service";
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setInvoiceData(param) {
      const _this = this;

      _this.jobCreate.job_title = param.title;
      _this.jobCreate.description = param.description;
      _this.jobCreate.property_person_notify = param.property_person_notify;
      _this.jobCreate.billing_person_notify = param.billing_person_notify;
      _this.jobCreate.reference = `Invoice # ${param.barcode}`;
      _this.jobCreate.discount_value = param.discount_value;
      _this.jobCreate.discount_value_type = param.discount_value_type;
      _this.jobCreate.tax_applied = param.tax_applied;

      _this.jobCreate.adjustment = param.adjustment;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          invoice: _this.invoiceId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              project_price: data[i].project_price,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              threshold_price: data[i].threshold_price,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getOptions() {
      this.pageLoading = true;
      this.$store

        .dispatch(QUERY, {
          url: "job/options",
          data: {
            customer: this.incidentCustomer,
            billing: this.incidentBilling,
            property: this.incidentProperty,
            property_person: this.incidentPropertyPerson,
            billing_person: this.incidentBillingPerson,
            incident: this.incidentId,
          },
        })
        .then(({ data }) => {
          if (!isEmpty(data.customer)) {
            this.customer = data.customer;
            this.incidentCustomer = this.customer.id;
            this.entityId = data.customer.entity_id;
          }

          if (!isEmpty(data.billing)) {
            this.billing = data.billing;
            this.incidentBilling = this.billing.id;
          }

          if (!isEmpty(data.property)) {
            this.property = data.property;
            this.incidentProperty = this.property.id;
          }

          if (!isEmpty(data.property_person)) {
            this.property_contact_person = data.property_person;
            this.incidentPropertyPerson = this.property_contact_person.id;
          }

          if (!isEmpty(data.billing_person)) {
            this.billing_contact_person = data.billing_person;
            this.incidentBillingPerson = this.billing_contact_person.id;
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    createJobSchedule({ job, schedule, team }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (
            isEmpty(schedule) === false &&
            moment(schedule.start_date).isValid()
          ) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/visit",
                data: {
                  barcodes: _this.visitBarcodes,
                  start_date: schedule.start_date
                    ? moment(schedule.start_date).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD"),
                  start_time: schedule.start_time
                    ? moment(schedule.start_time, ["h:mm A"]).format("HH:mm")
                    : moment().startOf("day").format("HH:mm"),
                  end_date: schedule.end_date
                    ? moment(schedule.end_date).format("YYYY-MM-DD")
                    : moment(schedule.start_date).format("YYYY-MM-DD"),
                  /* end_time: schedule.end_time
                      ? moment(schedule.end_time, ["h:mm A"]).format("HH:mm")
                      : moment().endOf("day").format("HH:mm"), */
                  end_time: schedule.end_time
                    ? moment(schedule.end_time, ["h:mm A"]).format("HH:mm")
                    : moment("23:45", ["HH:mm"]).format("HH:mm"),
                  email: team.email_team,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Invalid Start Date in Schedule.");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getSetting() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, { url: "incident-report/setting" })
        .then((data) => {
          this.userList = data.data.users;
          this.impactList = this.lodash.map(data.data.impact, function (row) {
            return {
              text: row.text,
              value: row.id,
            };
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    assignTeam({ job, visit, team, teamId }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (isArray(team) && team.length > 0) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/visit/" + visit + "/engineer",
                data: {
                  engineer: team,
                  teamId: teamId,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    assignRecurringTeam({ job, team, teamId }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (isArray(team) && team.length > 0) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/allocate",
                data: {
                  engineer: team,
                  teamId: teamId,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    createRecurringSchedule({ job, pattern, dates, schedule }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          const recurrenceData = pattern[pattern.type];
          let formData = new Object();
          switch (pattern.type) {
            case "daily": {
              formData = {
                day_count: toSafeInteger(recurrenceData.day_count),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.response_schedule.start_date,
                end_date: _this.response_schedule.end_date,
                start_time: _this.response_schedule.start_time,
                end_time: _this.response_schedule.end_time,
                recurring_pattern: toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
            case "weekly": {
              formData = {
                weeks: isArray(recurrenceData.weeks)
                  ? recurrenceData.weeks.join(",")
                  : null,
                week_count: toSafeInteger(recurrenceData.week_count),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.response_schedule.start_date,
                end_date: _this.response_schedule.end_date,
                start_time: _this.response_schedule.start_time,
                end_time: _this.response_schedule.end_time,
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
            case "monthly": {
              formData = {
                week_day: toSafeInteger(recurrenceData.week_day),
                day_count: toSafeInteger(recurrenceData.day_count),
                week_count: toSafeInteger(recurrenceData.week_count),
                month_count:
                  toSafeInteger(recurrenceData.recurring_pattern) === 1
                    ? toSafeInteger(recurrenceData.day_month_count)
                    : toSafeInteger(recurrenceData.month_count),
                recurring_pattern: toSafeInteger(
                  recurrenceData.recurring_pattern
                ),
                occurrence: toSafeInteger(schedule.occurrence),
                start_date: _this.response_schedule.start_date,
                end_date: _this.response_schedule.end_date,
                start_time: _this.response_schedule.start_time,
                end_time: _this.response_schedule.end_time,
                end_mode: toSafeInteger(schedule.end_mode),
                dates,
              };
              break;
            }
          }

          if (!pattern.type) {
            reject("Invalid Request");
          }

          formData.barcodes = _this.visitBarcodes;

          _this.$store
            .dispatch(PUT, {
              url: "job/" + job + "/" + pattern.type + "-recurrence",
              data: formData,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    initUpdate() {
      this.$store
        .dispatch(GET, { url: `job/update?job-id=${this.updateId}` })
        .then((response) => {
          this.jobCreate.reference = ObjectPath.get(
            response,
            "data.ticket.reference",
            null
          );
          this.jobCreate.job_title = ObjectPath.get(
            response,
            "data.ticket.title",
            null
          );
          this.jobCreate.attention = ObjectPath.get(
            response,
            "data.ticket.attention",
            null
          );
          this.jobCreate.po_number = ObjectPath.get(
            response,
            "data.ticket.po_number",
            null
          );
          this.jobCreate.payment_mode = ObjectPath.get(
            response,
            "data.ticket.payment_mode",
            0
          );
          this.jobCreate.description = ObjectPath.get(
            response,
            "data.ticket.description",
            null
          );
          this.jobCustomer = toSafeInteger(
            ObjectPath.get(response, "data.ticket.customer", 0)
          );
          this.jobBilling = toSafeInteger(
            ObjectPath.get(response, "data.ticket.billing", 0)
          );
          this.jobProperty = toSafeInteger(
            ObjectPath.get(response, "data.ticket.property", 0)
          );
          this.jobPropertyPerson = toSafeInteger(
            ObjectPath.get(response, "data.ticket.property_contact_person", 0)
          );
          this.jobBillingPerson = toSafeInteger(
            ObjectPath.get(response, "data.ticket.billing_contact_person", 0)
          );
          this.jobCreate.tags = ObjectPath.get(
            response,
            "data.ticket.tags",
            []
          );
          this.visitBarcodes = ObjectPath.get(
            response,
            "data.visit_barcode",
            []
          );

          let ticket_type = ObjectPath.get(
            response,
            "data.ticket.ticket_type",
            []
          );
          this.jobCreate.job_type = [];
          for (let i = 0; i < ticket_type.length; i++) {
            let found = this.lodash.find(this.jobTypeList, {
              value: ticket_type[i],
            });
            if (found) {
              this.jobCreate.job_type.push(found);
            }
          }

          let skills = ObjectPath.get(response, "data.ticket.skills", []);
          this.jobCreate.skill = [];
          for (let i = 0; i < skills.length; i++) {
            let found = this.lodash.find(this.skillList, { value: skills[i] });
            if (found) {
              this.jobCreate.skill.push(found);
            }
          }

          this.jobCreate.property_person_notify = ObjectPath.get(
            response,
            "data.ticket.property_person_notify",
            0
          );
          this.jobCreate.priority = ObjectPath.get(
            response,
            "data.ticket.priority",
            1
          );
          this.jobCreate.contract = ObjectPath.get(
            response,
            "data.ticket.contract",
            0
          );
          this.jobCreate.schedule_tab =
            ObjectPath.get(response, "data.ticket.type", 1) == 1
              ? "one-off"
              : "recurring";
          this.jobCreate.total_occurence = ObjectPath.get(
            response,
            "data.ticket.total_frequency",
            0
          );

          if (this.jobCreate.schedule_tab == "one-off") {
            JobScheduleTeamEventBus.$emit(
              "update-value:one-off-schedule",
              ObjectPath.get(response, "data.visit", {})
            );
          } else {
            JobScheduleTeamEventBus.$emit(
              "update-value:recurring-schedule",
              ObjectPath.get(response, "data.visit", {})
            );
          }

          JobEventBus.$emit(
            "update-value:line-item-calculation",
            ObjectPath.get(response, "data.ticket", {})
          );

          this.getLineItems({ job: this.updateId })
            .then((data) => {
              const result = [];
              for (let i = 0; i < data.length; i++) {
                result.push({
                  id: null,
                  group: data[i].group,
                  group_primary: data[i].group_primary,
                  product: data[i].product,
                  product_id: data[i].product_id,
                  serial_no: data[i].serial_no,
                  eq_model: data[i].eq_model,
                  location: data[i].location,
                  product_type: data[i].product_type,
                  project_price: data[i].project_price,
                  has_warranty: data[i].has_warranty,
                  warranty: data[i].warranty,
                  description: data[i].description,
                  rate: data[i].rate,
                  threshold_price: data[i].threshold_price,
                  quantity: data[i].quantity,
                  uom: data[i].uom,
                  total: data[i].total,
                  visit_barcode: data[i].visit_barcode,
                  is_optional: data[i].is_optional,
                });
              }

              this.$nextTick(() => {
                let products = filter(result, function (row) {
                  return (
                    row.product_type == "goods" || row.product_type == "service"
                  );
                });
                JobEventBus.$emit("update-value:line-item-products", products);
                // this.dbEquipments = filter(result, {product_type: "equipment" });
              });
            })
            .catch((error) => {
              this.logError(error);
            });

          TermConditionEventBus.$emit(
            "update-value:terms-conditions",
            ObjectPath.get(response, "data.ticket.term_conditions", null)
          );
          NoteAttachmentEventBus.$emit(
            "update-value:internal-notes",
            ObjectPath.get(response, "data.ticket.admin_remark", null)
          );
          NoteAttachmentEventBus.$emit(
            "update-value:attachments",
            ObjectPath.get(response, "data.document", [])
          );

          if (this.jobCreate.schedule_tab == "one-off") {
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Visit", route: "visit" },
              { title: "Update" },
              { title: ObjectPath.get(response, "data.visit.barcode", null) },
            ]);
          } else {
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Visit", route: "visit" },
              { title: "Update" },
              { title: ObjectPath.get(response, "data.ticket.barcode", null) },
            ]);
          }

          this.jobCreate.barcode = ObjectPath.get(
            response,
            "data.ticket.barcode",
            null
          );
          this.jobCreate.visit_barcode = ObjectPath.get(
            response,
            "data.visit.barcode",
            null
          );

          console.log({ response });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVisit() {
      this.$store
        .dispatch(GET, { url: "incident-report/" + this.incidentId })
        .then(({ data }) => {
          this.detail = data;
          (this.reportData.user = this.detail.user.id),
            (this.reportData.title = this.detail.title),
            (this.reportData.tags = this.detail.tags),
            (this.reportData.priority = this.detail.priority),
            (this.reportData.impact = this.detail.impact),
            (this.reportData.remark = this.detail.remark),
            (this.reportData.due_date = this.detail.due_date),
            (this.reportData.due_time = this.detail.due_time),
            (this.reportData.status = this.detail.status),
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Incident Report", route: "visit" },
              { title: "Create" },
              { title: this.reportData.barcode },
            ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
  },
  mounted() {
    this.getOptions();
    if (this.incidentId > 0) {
      this.getVisit();
    }
    this.getSetting();
    if (this.doUpdate) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Incident", route: "visit" },
        { title: "Update" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Incident", route: "visit" },
        { title: "Create" },
      ]);
    }

    JobScheduleTeamEventBus.$on("update:assigned-team", (argument, teamId) => {
      this.assigned_engineers = argument;
      this.teamId = teamId;
      // console.log(this.teamId, "heff");
    });

    JobScheduleTeamEventBus.$on("update:schedule-later", (argument) => {
      this.jobCreate.schedule_later = +argument;
    });

    JobEventBus.$on("update:one-off-schedule", (argument) => {
      this.one_off_schedule = argument;
    });

    JobEventBus.$on("update:recurring-pattern", (argument) => {
      this.recurring_pattern = argument;
    });

    JobEventBus.$on("update:recurring-schedule", (argument) => {
      this.recurring_schedule = argument;
    });

    JobEventBus.$on("update:recurring-dates", (argument) => {
      this.jobCreate.total_occurence = argument.length;
      this.recurring_dates = argument;
    });

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      this.terms_condition = argument;
    });

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      this.note_attachments = argument;
    });

    JobEventBus.$on("update:show-calendar", () => {
      this.show_calendar = !this.show_calendar;
    });

    JobScheduleTeamEventBus.$on("get:schedule-team", (argument) => {
      try {
        this.response_schedule = {
          start_date: argument.start_date
            ? moment(argument.start_date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          end_date: argument.end_date
            ? moment(argument.end_date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          start_time: argument.start_time
            ? moment(argument.start_time, ["h:mm A"]).format("HH:mm")
            : moment().startOf("day").format("HH:mm"),
          end_time: argument.end_time
            ? moment(argument.end_time, ["h:mm A"]).format("HH:mm")
            : moment().endOf("day").format("HH:mm"),
        };
      } catch (error) {
        this.logError(error);
      }
    });

    if (this.doUpdate) {
      this.initUpdate();
    }
  },
  beforeMount() {
    this.incidentId = toSafeInteger(this.$route.params.id);

    //this.jobId = toSafeInteger(this.$route.params.id);

    this.incidentCustomer = toSafeInteger(this.$route.query.customer);
    this.incidentProperty = toSafeInteger(this.$route.query.property);
    this.incidentPropertyPerson = toSafeInteger(
      this.$route.query.property_person
    );
    this.incidentBillingPerson = toSafeInteger(
      this.$route.query.billing_person
    );
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } /* else if (_this.jobCreated) {
      next();
    }  */ else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
